import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
//import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Card from 'common/src/components/Card';
//import Button from 'common/src/components/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import AboutUsSectionWrapper from './aboutUsSection.style';

const AboutUsSection = ({
  row,
  col,
  title,
  description,
  textArea,
  featureTitle,
  btnStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      image1: file(
        relativePath: { eq: "image/archerhealth/feature/Mimeo-Case-Study-1-min.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 505, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(
        relativePath: { eq: "image/archerhealth/feature/Mimeo-Case-Studies-3-min.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(
        relativePath: { eq: "image/archerhealth/feature/Mimeo-Case-Studies-2-min.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 505, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      agencyJson {
        aboutus {
          id        
          title
          image
          icon
        }
      }
    }
  `);

  return (
    <AboutUsSectionWrapper id="AboutUsSection">
      <Box className="row" {...row}>
        <Box className="col" {...col}>
          <Card className="group-gallery">
            <Box className="col1">
              <Fade top delay={30}>
                <Image
                  fluid={
                    (Data.image1 !== null) | undefined
                      ? Data.image1.childImageSharp.fluid
                      : {}
                  }
                  objectFit="contain"
                  alt="Feature Image 1"
                />
              </Fade>
              <Fade left delay={60}>
                <Image
                  fluid={
                    (Data.image3 !== null) | undefined
                      ? Data.image3.childImageSharp.fluid
                      : {}
                  }
                  objectFit="contain"
                  alt="Feature Image 3"
                />
              </Fade>
            </Box>
            <Box className="col2">
              <Fade bottom delay={90}>
                <Image
                  fluid={
                    (Data.image2 !== null) | undefined
                      ? Data.image2.childImageSharp.fluid
                      : {}
                  }
                  objectFit="contain"
                  alt="Feature Image 2"
                />
              </Fade>
            </Box>
          </Card>
        </Box>
        <Box className="col" {...col}>
          <Box {...textArea}>          
            
            <FeatureBlock
              title={
                <Heading
                  content="Mimeo is a leading online printing company, offering digital distribution &amp; online printing solutions."
                  {...title}
                />
              }
               /*description={
                 <Text
                   content="src/containers/agency/aboutUsSection/ Some hardworking People are Working Day and Night to provide you highly scalable product . "
                   {...description}
                 />
               }*/
              description={
                <div className="archer-health-about-us">
                  <h3>Client</h3>
                  <p style={{marginBottom: '32px'}}>Mimeo</p>

                  <h3>Engagement</h3>
                  <p style={{marginBottom: '32px'}}>Onsite clinic with lab services and medication dispensing for 400 employees</p>

                  <h3>Results</h3>
                  <p style={{marginBottom: '32px'}}>Total redirection savings: $22,140 in 6 weeks</p>
                </div>
              }
            />
          </Box>
          <Box {...textArea}>
            <FeatureBlock
              key={`feature_point-1`}
              icon={<i className={`flaticon-next`} />}              
              iconPosition="left"
              title={<Heading content={<span style={{fontWeight: '500'}}><span style={{color: '#19B8E8', fontSize: '20px', fontWeight: '500'}}>&raquo;</span> Emergency Room redirection savings of $7,500</span>} {...featureTitle} />}
            />
            <FeatureBlock
              key={`feature_point-1`}
              icon={<i className={`flaticon-next`} />}              
              iconPosition="left"
              title={<Heading content={<span style={{fontWeight: '500'}}><span style={{color: '#19B8E8', fontSize: '20px', fontWeight: '500'}}>&raquo;</span> Specialist redirection savings of $450</span>} {...featureTitle} />}
            />
            <FeatureBlock
              key={`feature_point-1`}
              icon={<i className={`flaticon-next`} />}
              iconPosition="left"
              title={<Heading content={<span style={{fontWeight: '500'}}><span style={{color: '#19B8E8', fontSize: '20px', fontWeight: '500'}}>&raquo;</span> Urgent care redirection savings of $3,900</span>} {...featureTitle} />}
            />
            <FeatureBlock
              key={`feature_point-1`}
              icon={<i className={`flaticon-next`} />}
              iconPosition="left"
              title={<Heading content={<span style={{fontWeight: '500'}}><span style={{color: '#19B8E8', fontSize: '20px', fontWeight: '500'}}>&raquo;</span> Primary care doctor redirection savings of $10,590</span>} {...featureTitle} />}
            />
          </Box>
          <Box {...textArea}>
            <FeatureBlock
              description={
                <div style={{fontSize: '14px', marginBottom: '50px'}}>                  
                  <p style={{marginTop: '20px', marginBottom: '20px', lineHeight: '30px', color: '#3D636C'}}>"Archer Health has proven to be an invaluable asset to our organization. Our internal wellness scores have increased substantially since implementing and our employee usage has been through the roof. In addition, our absenteeism has decreased while engagement scores have consistently increased. I am thoroughly confident this is a direct result of utilizing Archer Health's services. The team has been an absolute pleasure to work with and this investment in our employees has truly resulted in quantifiable gains for our organization. I could not recommend their services enough."</p>
                  <p style={{color: '#19B8E8', lineHeight: '25px'}}><strong>Adam Reding</strong><br/>
                  Director of Talent Operations, Mimeo</p>
                  <p><img src="/archerhealth/feature/Logo-Mimeo.png" alt="Mimeo Logo" /></p>
                </div>
              }
            />
          </Box>
        </Box>
      </Box>
    </AboutUsSectionWrapper>
  );
};

AboutUsSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

AboutUsSection.defaultProps = {
  // About us section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // About us section col default style
  col: {
    width: [1, '100%', '50%'],
  },
  // About us section text area default style
  textArea: {
    maxWidth: '540px',
    pl: '40px',
  },
  // About us section title default style
  title: {
    fontSize: ['26px', '26px', '30px', '40px'],
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#3D636C',
    letterSpacing: '-0.025em',
    mb: '30px',
  },
  // About us section description default style
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },

  // feature title default style
  featureTitle: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#3D636C',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',   
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
};

export default AboutUsSection;
